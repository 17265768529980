import { defineStore } from 'pinia'
import { useFileStore } from './file'
import { uuid } from 'vue-uuid'
import { addStatusInStudy, } from 'src/utils/helpers'
import { ref, computed } from 'vue'
import { InProgressStatus, processStatus } from 'src/components/User/StationBalancing/config'
import StationService from 'src/services/station'
import CellService from 'src/services/cell.js'
import { formatCellStudy } from 'src/utils/cellLayout'
import { statusConfig } from 'src/components/User/CellOptimization/config'


export const useProductStore = defineStore('cell', () => {
  const cellStudyId = ref(null)
  const cellStudy = ref({})
  const studies = ref([])
  const isFetchingStudies = ref(false)
  const totalStudies = ref(0)
  const workCycles = ref([])
  const workRegionList = ref([])
  const regionsLoading = ref(false)
  const isOutlineComplete = ref(false)
  const areProcessesRunning = ref(false)

  const videoFPS = computed(() => cellStudy.value.file?.meta?.fps)
  const regionsIdsToIndexMap = computed(() => {
    if (!workRegionList.value.length) return {}
    return workRegionList.value.reduce((res, el, index) => {
      res[el.id] = index
      return res
    }, {})
  })

  const areAllProcessesCompleted = computed(() => {
    return cellStudy.value?.status === statusConfig.Completed.name
  })

  const setAreProcessesRunning = (value) => {
    areProcessesRunning.value = value
  }

  const resetNewStudyState = () => {
    cellStudy.value = {}
  }

  const setOutlineComplete = (value) => {
    isOutlineComplete.value = value
  }

  const updateWorkCycleInList = (cycles) => {
    workCycles.value = [...cycles]
  }

  const setRegionsLoading = (payload) => {
    regionsLoading.value = payload
  }

  const setWorkRegionsList = (payload) => {
    workRegionList.value = payload
  }

  const fetchStudies = async (params, loading = true) => {
    if (loading) isFetchingStudies.value = true
    const [error, data] = await CellService.fetchCellStudies(params)
    isFetchingStudies.value = false
    if (error) return
    const { count, results } = data
    totalStudies.value = count
    studies.value = results.map(formatCellStudy)
  }

  const createCell = async (payload) => { // remove
    const [error, data] = await StationService.createStation(payload)
    if (error) {
      console.log('error:', error?.response?.data)
      return error?.response?.data || {}
    }
    return data
  }

  const createNewStudy = async (payload) => {
    const [error, data] = await CellService.createCellStudy(payload)
    if (error) {
      console.log('error:', error)
      return 0
    }
    return data.id
  }

  const updateCellStudy = async (id, payload, callStudy = true) => {
    const [error] = await CellService.updateCellStudy(id, payload)
    if (error) {
      console.log('error:', error)
      return error?.response?.data
    }
    if (callStudy) await fetchStudy(cellStudyId.value)
    return {}
  }

  const fetchStudy = async (studyId) => {
    const { fetchPresignedUrl } = useFileStore()
    const [error, data] = await CellService.fetchCellStudy(studyId)
    if (error) {
      console.log('error:', error)
      return 0
    }
    let url = cellStudy.value.url
    if (!url || data.file.id !== cellStudy.value.file.id) url = await fetchPresignedUrl(data.file.location)
    cellStudy.value = formatCellStudy({ ...data, url })
    cellStudyId.value = data.id
  }

  const deleteStudy = async (studyId) => {
    const [error] = await CellService.deleteCellStudy(studyId)
    if (error) {
      console.log('error: ', error)
      return 0
    }
    return 1
  }

  const fetchWorkCyles = async () => {
    const [error, data] = await CellService.fetchWorkCycles(cellStudyId.value)
    if (error) {
      console.log('error: ', error)
      return 0
    }
    workCycles.value = [...data].map(({ cycle_end_frame_no, cycle_start_frame_no, ...res }, index) => ({
      ...res,
      cycle_end_frame_no,
      cycle_start_frame_no,
      segment_start: cycle_start_frame_no / videoFPS.value,
      segment_end: cycle_end_frame_no / videoFPS.value,
      cycle_time_duration: (cycle_end_frame_no - cycle_start_frame_no) / videoFPS.value,
      cycle_index: index
    })
    )
  }

  const addWorkCycles = async (payload) => {
    const [error] = await CellService.createWorkCycles(cellStudyId.value, payload)
    if (error) {
      console.log('error: ', error)
      return 0
    }
  }

  const updateWorkCycle = async (cycleId, payload) => {
    const [error, data] = await CellService.updateWorkCycle(cycleId, payload)
    if (error) {
      console.log('error: ', error)
      return
    }
    return data
  }

  const deleteWorkCycle = async (cycleId) => {
    const [error, data] = await CellService.removeWorkCycle(cycleId)
    if (error) {
      console.log('error: ', error)
      return 0
    }
    return data
  }

  const fetchWorkRegions = async () => {
    const [error, data] = await CellService.fetchWorkRegions(cellStudyId.value)
    if (error || !data.length) {
      setWorkRegionsList([])
      return
    }
    const regions = data.map((r) => ({ ...r, name: uuid.v4() }))
    setWorkRegionsList(regions)
  }

  const addWorkRegions = async (payload) => {
    const [error] = await CellService.createWorkRegions(cellStudyId.value, payload)
    if (error) {
      console.log('error: ', error)
      return 0
    }
  }

  const updateWorkRegion = async (regionId, payload) => {
    const [error, data] = await CellService.updateWorkRegion(regionId, payload)
    if (error) {
      console.log('error: ', error)
      return 0
    }
    return data
  }

  const deleteRegion = async (regionId) => {
    const [error, data] = await CellService.removeWorkRegion(regionId)
    if (error) {
      console.log('error: ', error)
      return 0
    }
    return data
  }

  // const getCycleVideoData = async (cycleId) => {
  //   const [error, data] = await StationService.getWorkCycle(cellStudyId.value, cycleId)
  //   if (error) return 0
  //   const { video_file } = data || {}
  //   const cycleVideoObj = { location: video_file?.location, status: video_file?.process?.status?.name, progress: video_file?.process?.progress }
  //   return cycleVideoObj
  // }

  // update
  // const setMaterCycleVideoUrl = () => {
  //   if (!workCycles.value.length) return
  //   const masterCycle = this.workCycles.find((cycle) => cycle.is_master === true)
  //   if (!masterCycle || !this.studyFilesObject?.[masterCycle?.file]) return
  //   this.masterCycleVideoId = masterCycle.file
  //   this.masterCycleFileUrl = this.studyFilesObject[masterCycle.file]?.url
  // }

  // const updateStudy = async (id, payload) => {
  //   const patchPayload = {}
  //   if (payload.projectName) {
  //     patchPayload.project_name = payload.projectName
  //   }

  //   if ('stepCount' in payload) {
  //     patchPayload.no_of_steps = payload.stepCount
  //   }

  //   if ('isProcessed' in payload) {
  //     patchPayload.is_processed = payload.isProcessed
  //   }

  //   if ('file' in payload) {
  //     patchPayload.file = payload.file
  //   }

  //   if ('workCycle' in payload) {
  //     if (payload.workCycle) {
  //       patchPayload.work_cycle = {
  //         cycle_start_frame_no: payload.workCycle.start,
  //         cycle_end_frame_no: payload.workCycle.end
  //       }
  //     } else {
  //       patchPayload.work_cycle = payload.workCycle
  //     }
  //   }

  //   if (isMockingEnabled.value) {
  //     return new Promise((resolve) =>
  //       setTimeout(() => {
  //         resolve(10)
  //       }, 2000)
  //     )
  //   } else {
  //     const [err, data] = await CellService.patchStudy(id, patchPayload)
  //     if (err) {
  //       return err
  //     }
  //     return data.id
  //   }
  // }

  return {
    cellStudyId,
    cellStudy,
    studies,
    isFetchingStudies,
    totalStudies,
    workCycles,
    workRegionList,
    isOutlineComplete,
    areProcessesRunning,
    videoFPS,
    regionsIdsToIndexMap,
    areAllProcessesCompleted,
    regionsLoading,
    setAreProcessesRunning,
    resetNewStudyState,
    setRegionsLoading,
    setOutlineComplete,
    updateWorkCycleInList,
    setWorkRegionsList,
    fetchStudies,
    createCell,
    createNewStudy,
    updateCellStudy,
    fetchStudy,
    deleteStudy,
    fetchWorkCyles,
    addWorkCycles,
    updateWorkCycle,
    deleteWorkCycle,
    fetchWorkRegions,
    addWorkRegions,
    updateWorkRegion,
    deleteRegion,
  }
})
