import { useUserStore } from "src/stores/user"
import { dateFormat, formatDate, getStudyServicesStatus } from "./helpers"
import { statusConfig } from "src/components/User/CellOptimization/config"

export const formatCellStudy = (study) => {
    const { userIdToNameMap } = useUserStore()
    let obj = {
        ...study,
        progress: study.process?.progress ? Math.floor(study.process?.progress) : 0,
        time_created: formatDate(study.time_created, dateFormat),
        isCompleted: study.process.status?.name === study.process.completed_status.name,
        isProcessesRemaining: study.process.status?.name ? study.process.status?.name !== study.process.completed_status.name : true,
        isNotStarted: study.process.status === null,
        isFailed: study.process.status?.name === statusConfig['Failed'].name,
        created_by: userIdToNameMap[study.created_by] || '-'
    }
    obj['status'] = getStudyServicesStatus(obj)
    return obj
}

export const getIndexedJsonOfRegionIds = (json, idsToIndexMap) => {
    let indexed = {}
    for (let k in json) {
        let key = JSON.parse(k)
        const x = idsToIndexMap[key[0]]
        const y = idsToIndexMap[key[1]]
        indexed[[x, y]] = json[k]
    }
    return indexed
}