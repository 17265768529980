<template>
  <div
    class="position-relative d-flex flex-column align-items-center"
    :style="{ ...wrapperStyles }"
  >
    <video
      ref="video"
      muted
      disablePictureInPicture
      class="preview-segment-video"
      :style="{ ...styles }"
      crossorigin="anonymous"
      :src="videoUrl"
      :key="videoUrl"
      @seeked="onVideoSeeked"
      @loadedmetadata="onVideoMetaLoaded"
      @playing="isPlayingSegment = true"
    ></video>

    <a-flex vertical class="timeline-wrapper w-100">
      <a-flex class="video-controls w-100" justify="space-between">
        <a-flex style="width: 50px">
          {{ segmentStartTime }}
        </a-flex>
        <a-flex v-if="videoVisible" class="player-icons" gap="small">
          <step-backward-outlined @click="handleMoveBackward" />
          <pause-outlined v-if="isPlayingSegment" @click="pauseSegment" />
          <caret-right-outlined v-else @click="playSegment" />
          <step-forward-outlined @click="handleMoveForward" />
        </a-flex>
        <a-flex style="width: 50px" justify="flex-end">{{
          segmentPlay.end ? getIntervalTime(segmentPlay.end * 1000) : 0
        }}</a-flex>
      </a-flex>

      <div ref="timeline" class="video-timeline" @click="(e) => handleClickTimeline(e, 'timeline')">
        <div
          class="h-100 current-progress"
          :style="{ width: (progress / (segmentPlay.end - segmentPlay.start)) * 100 + '%' }"
        >
          <span :class="['seeker', progress > 0.1 ? 'visible' : '']"></span>
        </div>
      </div>
    </a-flex>
  </div>
</template>
<script>
import { getIntervalTime, getFormattedTime } from 'src/utils/outline'
import {
  CaretRightOutlined,
  PauseOutlined,
  StepForwardOutlined,
  StepBackwardOutlined
} from '@ant-design/icons-vue'

export default {
  props: [
    'videoUrl',
    'segmentPlay',
    'durationTime',
    'framePerSec',
    'wrapperStyles',
    'styles',
    'playbackRate'
  ],
  emits: ['updatePlaybackRate'],
  components: {
    CaretRightOutlined,
    PauseOutlined,
    StepForwardOutlined,
    StepBackwardOutlined
  },
  setup() {
    return {
      getIntervalTime,
      getFormattedTime
    }
  },
  data() {
    return {
      progress: 0,
      selectedSegment: null,
      isPlayingSegment: false,
      segmentPlayInterval: null,
      videoVisible: false,
      videoDurationTime: 1
    }
  },
  beforeUnmount() {
    this.pauseSegment()
    this.videoVisible = false
    clearInterval(this.segmentPlayInterval)
  },
  watch: {
    segmentPlay() {
      this.onVideoMetaLoaded()
    }
  },
  computed: {
    segmentStartTime() {
      if (!this.segmentPlay) return 0
      return this.durationTime < 1
        ? this.getIntervalTime(this.segmentPlay.start * 1000)
        : this.getIntervalTime((this.segmentPlay.start + this.progress) * 1000)
    }
  },
  methods: {
    handlePlaybackRateChange(value) {
      this.$emit('updatePlaybackRate', value)
    },

    getInitialTime() {
      if (this.$refs.video) {
        return getFormattedTime(this.$refs.video.currentTime)
      } else {
        return getIntervalTime(this.progress * 1000)
      }
    },

    onVideoMetaLoaded() {
      this.progress = 0
      this.videoDurationTime = this.durationTime
      if (!this.$refs.video || !this.segmentPlay) return
      this.$refs.video.currentTime = this.segmentPlay.start
      if (!this.$refs.video.paused && this.isPlayingSegment) {
        this.$refs.video?.pause()
        this.isPlayingSegment = false
      }
      clearInterval(this.segmentPlayInterval)
      this.segmentPlayInterval = null
    },

    onVideoSeeked() {
      if (
        !this.videoVisible &&
        Math.floor(this.$refs.video?.currentTime) == Math.floor(this.segmentPlay?.start)
      ) {
        this.videoVisible = true
      }
    },

    pauseSegment() {
      if (!this.$refs.video) return
      if (!this.$refs.video.paused && this.isPlayingSegment) {
        this.$refs.video.pause()
        this.isPlayingSegment = false
      }
      clearInterval(this.segmentPlayInterval)
    },

    async playSegment() {
      if (!this.videoVisible || !this.$refs.video) return
      if (this.$refs.video.paused && !this.isPlayingSegment) {
        await this.$refs.video?.play()
        this.segmentTimeUpdate()
        if (this.segmentPlayInterval) clearInterval(this.segmentPlayInterval)
        this.segmentPlayInterval = setInterval(this.segmentTimeUpdate, 100 / this.framePerSec)
      }
    },

    segmentTimeUpdate() {
      if (this.videoDurationTime && this.progress >= this.videoDurationTime) {
        this.progress = 0
        this.$refs.video.currentTime = this.segmentPlay.start
        this.pauseSegment()
        return
      }
      if (this.$refs.video) {
        this.progress = this.$refs.video?.currentTime - this.segmentPlay.start
      }
    },

    handleMoveForward() {
      if (!this.videoVisible) return
      const { video } = this.$refs
      if (video.currentTime + 3 >= this.segmentPlay.end) {
        video.currentTime = this.segmentPlay.end
        this.progress = 0
        this.pauseSegment()
        return
      }
      this.$refs.video.currentTime = video.currentTime + 3
      this.progress = this.$refs.video?.currentTime - this.segmentPlay.start
    },

    handleMoveBackward() {
      if (!this.videoVisible) return
      const { video } = this.$refs
      if (video.currentTime - 3 <= this.segmentPlay.start) {
        video.currentTime = this.segmentPlay.start
        this.progress = 0
        this.pauseSegment()
        return
      }
      this.$refs.video.currentTime = video.currentTime - 3
      this.progress = this.$refs.video?.currentTime - this.segmentPlay.start
    },

    handleClickTimeline(e, timelineRef) {
      if (!this.$refs.video || !this.videoVisible || e.target.className?.includes('seeker')) return
      const clientX = e.offsetX
      const timeline = this.$refs[timelineRef]
      if (!clientX || !timeline) return
      const timelineWidth = timeline?.getBoundingClientRect()?.width
      const time = (clientX / timelineWidth) * Number(this.videoDurationTime)
      this.$refs.video.currentTime = time + this.segmentPlay.start
      this.progress = this.$refs.video?.currentTime - this.segmentPlay.start
    }
  }
}
</script>

<style scoped>
.preview-segment-video {
  width: 100%;
  border: 1px solid lightgray;
  pointer-events: none;
  border-radius: 0.5em;
  max-height: 350px;
  display: block;
}

.player-icons {
  font-size: 20px;
  cursor: pointer;
}

.preview-video-shadow {
  display: flex;
  padding: 10px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(#00000000, #000000);
}

.video-playback-control {
  justify-content: space-between;
  align-items: center;
  margin-left: 230px;
}

.video-timeline {
  height: 8px;
  background: lightgray;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
}

.video-timeline .current-progress {
  background: rgb(24, 144, 255);
  border-radius: 5px;
  position: relative;
}

.seeker {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: -50%;
  right: 0;
  display: flex;
  justify-content: center;
  transform: translateX(50%);
  z-index: 99;
  background-color: rgb(24, 144, 255);
  visibility: hidden;
}

.seeker.visible {
  visibility: visible;
}

:deep(.playback-speed-dropdown .ant-select-selector) {
  padding: 0px 20px !important;
  border-radius: 0 !important;
  height: 20px !important;
}

:deep(.playback-speed-dropdown .ant-select-selector .ant-select-selection-item) {
  line-height: 20px !important;
}
</style>

<style>
.playback-speed-dropdown-options.ant-select-dropdown {
  border-radius: 0 !important;
  width: 53px !important;
}
</style>
