import { PublicClientApplication } from '@azure/msal-browser'
import { defineStore } from 'pinia'
import { clearLocalStorageExcept } from 'src/config/auth-config'
import { roles } from 'src/config/roles-config.js'
import router from 'src/router'
import AuthService from 'src/services/auth'
import { isUserAssignedToOrg } from 'src/utils/auth'
import { customErrorHandler, defaultError, handleError } from 'src/utils/error'
import { useToast } from 'vue-toastification'
import { useLineBalanceStore } from './lineBalancing' // CD => need to fix 

const toast = useToast()

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isSidebarOpen: true,
    user: localStorage.getItem('name'),
    token: localStorage.getItem('token'),
    refresh: null,
    isTokenSet: !!localStorage.getItem('token'),
    organization: localStorage.getItem('organization'),
    role: localStorage.getItem('role'),
    hostURL: null,
    poc: null,
    isPolicyAccepted: null,
    accessToken: null,
    msalInstance: null,
    azureUser: false,
    isOtpSetup: null,
    email: localStorage.getItem('email'),
    requireOtp: null,
    isSigningIn: false,
    AWSCustomerIdentifier: null,
    sessionKey: '',
    userIdAndToken: null,
    linkedOrganizations: []
  }),
  actions: {
    setUser(value) {
      this.user = value
    },
    setToken(value) {
      this.token = value
      this.isTokenSet = value ? true : false
    },
    setOrganization(value) {
      this.organization = value
    },
    setRole(value) {
      this.role = value
    },
    setIsPolicyAccepted(value) {
      this.isPolicyAccepted = value
    },
    setEmail(value) {
      this.email = value
    },
    setIsSidebarOpen(value) {
      this.isSidebarOpen = value
    },
    setMsalInstance(value) {
      this.msalInstance = value
    },
    setAWSCustomerIdentifier(value) {
      this.AWSCustomerIdentifier = value
    },
    setSessionKey(value) {
      this.sessionKey = value
    },
    setOtpSetup(value) {
      this.isOtpSetup = value
    },
    setRequireOtp(value) {
      this.requireOtp = value
    },
    setAzureUser(value) {
      this.azureUser = value
    },

    async createMsalInstance() {
      const msalConfig = {
        auth: {
          clientId: 'c5093c91-21ae-4374-9be1-d95791ea78eb',
          authority: 'https://login.microsoftonline.com/organizations'
        },
        cache: {
          cacheLocation: 'localStorage'
        }
      }
      this.msalInstance = new PublicClientApplication(msalConfig)
    },

    async azureSignIn(payload) {
      const [error, data] = await AuthService.azureSignIn(payload)
      if (error) {
        toast.error(error.response?.data?.response || defaultError)
        this.clearAllStates()
        localStorage.clear()
        sessionStorage.clear()
        this.isOtpSetup = false
        this.requireOtp = true
        return
      }

      if (data.response === 'User created successfully') {
        setTimeout(toast.success(data.response), 3000)
        this.clearAllStates()
        localStorage.clear()
        sessionStorage.clear()
        this.isOtpSetup = false
        this.requireOtp = true
        return
      } else {
        if (isUserAssignedToOrg(data)) {
          return toast.error('User is not assigned to any organization')
        }
      }
      let userInfo = { ...data, email: payload.username }
      let token = payload.token
      this.userIdAndToken = { token, id: userInfo.id }
      this.setUserDetails({ ...data, email: payload.username })
      this.fetchOrganizations()
      localStorage.setItem('azureUser', true)
    },

    async userLogin(payload) {
      this.isSigningIn = true
      const [error, data] = await AuthService.signIn(payload)
      this.isSigningIn = false
      if (error) {
        handleError(error?.response)
        return false
      }
      const { aws_customer_identifier } = data
      if (!aws_customer_identifier) {
        if (isUserAssignedToOrg(data)) {
          if (error?.response?.status === 429) {
            toast.error('Too many attempts.Please try after 1 min.')
            return false
          }
          return handleError(error?.response)
        }
      }
      localStorage.setItem('azureUser', false)

      let userInfo = { ...data, email: payload.email }
      let token = payload.token
      this.userIdAndToken = { token, id: userInfo.id }
      this.setUserDetails({ ...data, email: payload.email })
      // If verified redirect
      if (!data.require_otp) {
        this.fetchOrganizations()
        return true
      }
      // Handle OTP
      if (this.requireOtp && data.receive_otp_on == 'email') {
        await AuthService.sendOTP()
        return true
      }
      return true
    },

    setUserDetails(data) {
      const {
        id,
        name,
        email,
        role,
        token,
        Organization,
        poc,
        is_policy_accepted,
        is_otp_verified,
        require_otp,
        refresh,
        session_key,
        aws_customer_identifier
      } = data
      const username = role === roles.admin ? 'Andrey Konin' : name
      const org = role === roles.admin ? 'ADMINISTRATOR' : Organization

      const currentTime = new Date()
      window.localStorage.setItem('id', id)
      window.localStorage.setItem('name', username)
      window.localStorage.setItem('email', email)
      window.localStorage.setItem('role', role)
      window.localStorage.setItem('organization', org)
      window.localStorage.setItem('token', token)
      window.localStorage.setItem('refresh', refresh)
      window.localStorage.setItem('timestamp', currentTime)
      window.localStorage.setItem('poc', poc)
      window.localStorage.setItem('isPolicyAccepted', is_policy_accepted)
      window.localStorage.setItem('sessionKey', session_key)
      window.localStorage.setItem('AWSCustomerIdentifier', aws_customer_identifier)

      this.setUser(username)
      this.setEmail(email)
      this.setToken(token)
      this.setOrganization(org)
      this.setRole(role)
      this.setRequireOtp(require_otp)
      this.setOtpSetup(is_otp_verified)
      this.setIsPolicyAccepted(is_policy_accepted)
      this.setAWSCustomerIdentifier(aws_customer_identifier)
      this.setSessionKey(session_key)
    },

    clearState() {
      if (this.isTokenSet) {
        AuthService.logout()
      }
      this.clearAllStates()
      // localStorage.clear()
      sessionStorage.clear()
      router.replace({ name: 'Login' })
    },

    clearAllStates() {
      this.setUser(null)
      this.setEmail(null)
      this.setToken(null)
      this.setOrganization(null)
      this.setRole(null)
      this.setIsPolicyAccepted(null)
      this.setSessionKey(null)
      this.setAWSCustomerIdentifier(null)
    },

    async userLogout() {
      if (this.isTokenSet) {
        await AuthService.logout()
      }
      //  clear all keys from localStorage except that keep track for Demo functionality
      this.clearAllStates()
      clearLocalStorageExcept()
      sessionStorage.clear()
    },

    async forgotPassword(payload) {
      const [error, data] = await AuthService.forgotPassword(payload)
      if (error) return customErrorHandler(error?.response)
      toast.success(data.response)
      router.replace({ name: 'Login' }, 3000)
    },

    async changePassword(payload) {
      const [error, data] = await AuthService.changePassword(payload)
      if (error) return customErrorHandler(error?.response)
      toast.success(data.response)
      router.replace({ name: 'Login' }, 3000)
    },

    async resendOTP() {
      const [error] = await AuthService.resendOtp()
      if (error) {
        if (error?.repsonse?.data) customErrorHandler(error?.response)
        else toast.error('Error occurred, Please try login again!')
        return
      }
      toast.success('OTP Sent!')
    },

    async verifyOTP(payload) {
      const [error] = await AuthService.verifyOTP(payload)
      if (error) {
        customErrorHandler(error?.response)
        return false
      }
      this.fetchOrganizations()
      return true
    },

    async fetchOrganizations() {
      let params = { user_id: localStorage.getItem('id') }
      const [error, data] = await AuthService.getUserLinkedOrgByUserId(params)
      if (error) {
        customErrorHandler(error?.response)
        return
      }
      if (data.length == 1) {
        let payload = {
          organization: data[0].organization
        }
        this.selectOrganization(payload)
      } else {
        this.linkedOrganizations = data
        // remove these right after getting user linked organization, so that if user refresh page he couldn't access home page
        localStorage.removeItem('token')
        localStorage.removeItem('id')
      }
    },

    async selectOrganization(payload) {
      await AuthService.switchOrganizationByUser(payload)

      localStorage.setItem('organization', payload.organization)
      localStorage.setItem('token', this.userIdAndToken.token)
      localStorage.setItem('id', this.userIdAndToken.id)

      this.token = this.userIdAndToken.token
      this.organization = payload.organization
      router.replace({ path: '/' }).then(() => {
        this.linkedOrganizations = []
      })
    }
  }
})
