import ApiHelper from './index'

const fetchCellStudies = async (params) => {
  return await ApiHelper.get('cell_layout/study', params)
}

const createCellStudy = async (payload) => {
  return await ApiHelper.post('cell_layout/study', payload)
}

const fetchCellStudy = async (id) => {
  return await ApiHelper.get(`cell_layout/study/${id}/`)
}

const updateCellStudy = async (id, payload) => {
  return await ApiHelper.patch(`cell_layout/study/${id}/`, payload)
}

const deleteCellStudy = async (id) => {
  return await ApiHelper.remove(`cell_layout/study/${id}/`)
}

const fetchWorkCycles = async (studyId) => {
  return await ApiHelper.get(`cell_layout/work_cycles/${studyId}/`)
}

const createWorkCycles = async (studyId, payload) => {
  return await ApiHelper.post(`cell_layout/work_cycles/${studyId}/`, payload)
}

const updateWorkCycle = async (cycleId, payload) => {
  return await ApiHelper.patch(`cell_layout/work_cycle/${cycleId}/`, payload)
}

const removeWorkCycle = async (cycleId) => {
  return await ApiHelper.remove(`cell_layout/work_cycle/${cycleId}/`)
}

const fetchWorkRegions = async (studyId) => {
  return await ApiHelper.get(`cell_layout/work_regions/${studyId}/`)
}

const createWorkRegions = async (studyId, payload) => {
  return await ApiHelper.post(`cell_layout/work_regions/${studyId}/`, payload)
}

const updateWorkRegion = async (regionId, payload) => {
  return await ApiHelper.patch(`cell_layout/work_region/${regionId}/`, payload)
}

const removeWorkRegion = async (regionId) => {
  return await ApiHelper.remove(`cell_layout/work_region/${regionId}/`)
}


export default {
  fetchCellStudies,
  createCellStudy,
  updateCellStudy,
  fetchCellStudy,
  deleteCellStudy,
  fetchWorkCycles,
  createWorkCycles,
  updateWorkCycle,
  removeWorkCycle,
  fetchWorkRegions,
  createWorkRegions,
  updateWorkRegion,
  removeWorkRegion
}
