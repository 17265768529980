export const studiesColumn = [
  {
    title: '#',
    dataIndex: 'id',
    align: 'center',
    width: '5%'
  },
  {
    title: 'Thumbnail',
    dataIndex: 'icon',
    key: 'icon',
    align: 'center',
    width: '15%'
  },

  {
    title: 'Cell ID',
    dataIndex: 'project_name', //TODO => add cell data
    key: 'project_name',
    align: 'left',
    width: '15%'
  },
  {
    title: 'Product',
    key: 'product',
    dataIndex: 'product',
    align: 'center',
    width: '10%'
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    align: 'center',
    width: '10%'
  },
  {
    title: 'Created By',
    dataIndex: 'created_by',
    align: 'center',
    width: '10%'
  },
  {
    title: 'Published Date',
    dataIndex: 'time_created',
    key: 'time_created',
    align: 'center',
    sorter: true
  },
  {
    title: 'Actions',
    key: 'action',
    dataIndex: 'action',
    width: '12%',
    align: 'center'
  }
]


export const statusConfig = {
  Pending: { name: 'Pending', color: 'default' },
  Processing: { name: 'Processing', color: 'processing' },
  Completed: { name: 'Completed', color: 'success' },
  Failed: { name: 'Failed', color: 'error' }
}

export const statusColorMap = {
  Completed: 'success',
  Failed: 'error',
  Processing: 'processing',
  Pending: 'default'
}

export const stepsMenuItem = {
  remove: 'Remove Cycle',
  editStart: 'Edit Cycle Start',
  editEnd: 'Edit Cycle End'
}

export const cellOutlineGuidelines = {
  'Pre-Requisite: ': [
    'To proceed, Select the required number of Work Regions from the provided dropdown menu.',
    'Afterward, you will see the table to mark the regions and operator for the selected number.'
  ],
  'Work Regions: ': [
    'Click on the Mark region button for any region in the table and mark the outline of the region.',
    'Start marking the region from the anticlock-wise direction.',
    'Adjust corners for proper region coverage if necessary.'
  ]
}

export const colors = {
  0: '#34568B',
  1: '#009B77',
  2: '#EFC050',
  3: '#88B04B',
  4: '#F7CAC9',
  5: '#92A8D1',
  6: '#955251',
  7: '#B565A7',
  8: '#FF6F61',
  9: '#DD4124',
  10: '#D65076',
  11: '#45B8AC',
  12: '#6B5B95'
}

export const sequenceTableHeader = {
  name: 'Operators',
  route: 'Route',
  distance: 'Total Walk Distance',
  moves: 'Number of moves'
}