import axios from 'axios'
import URL from './baseUrl'
import AuthService from './auth.js'
import { getUploadConfig } from './config'
import { clearLocalStorageExcept } from 'src/config/auth-config'

export function getBaseUrl() {
  var hostURL = localStorage.getItem('baseURL')
  if (hostURL == null) {
    localStorage.setItem('baseURL', URL.prodHostUrl)
    hostURL = localStorage.getItem('baseURL')
  }
  return hostURL
}

const baseURL = import.meta.env.MODE === 'production' ?  import.meta.env.VITE_BASEURL : getBaseUrl()
export const axiosInstance = axios.create({
  baseURL
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const sessionKey = localStorage.getItem('sessionKey');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (sessionKey) {
      config.headers['SessionKey'] = sessionKey;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false
let requestQueue = []

const processQueue = (error, token = null) => {
  requestQueue.forEach((pendingRequest) => {
    if (error) {
      pendingRequest.reject(error)
    } else {
      pendingRequest.resolve(token)
    }
  })
  requestQueue = []
}

const onResponseError = async (error) => {
  const originalRequest = error.config
  const status = error.response.status
  const refreshUrl = ['account/token/refresh/', 'account/refresh-azure-token/']

  if (refreshUrl.includes(originalRequest.url)) {
    axios.patch('account/logout/').finally(() => {
      clearLocalStorageExcept()
      sessionStorage.clear()
      window.location.href = '/login'
    })
    return
  }

  if (status === 401 && !originalRequest._retry) {
    originalRequest._retry = true

    if (['account/login/', 'account/logout/'].includes(originalRequest.url)) {
      return
    }

    if (!isRefreshing) {
      isRefreshing = true
      return onRefreshJWTToken()
        .then((token) => {
          if (token) {
            originalRequest.headers.Authorization = `Bearer ${token}`
            processQueue(null, token)
            return axiosInstance(originalRequest)
          }
        })
        .catch((refreshError) => {
          processQueue(refreshError, null)
          return Promise.reject(refreshError)
        })
        .finally(() => {
          isRefreshing = false
        })
    }

    return new Promise((resolve, reject) => {
      requestQueue.push({ resolve, reject })
    })
      .then((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`
        return axiosInstance(originalRequest)
      })
      .catch((queueError) => {
        return Promise.reject(queueError)
      })
  }

  return Promise.reject(error)
}

async function onRefreshJWTToken() {
  const refreshToken = localStorage.getItem('refresh');
  const payload = { refresh: refreshToken };
  const [error, data] = await AuthService.refreshJWTToken(payload);
  if (error) return null
  localStorage.setItem('token', data.access);
  localStorage.setItem('refresh', data.refresh);
  return data.access;
}

axiosInstance.interceptors.response.use(response => response, error => onResponseError(error))

async function get(url, queryParams = {}, customBaseURL=null) {
  try {
    const { data } = await axiosInstance.get(url, {
      params: { ...queryParams },
      baseURL: customBaseURL || axiosInstance.defaults.baseURL,
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function download(url, queryParams = {},) {
  try {
    const { data } = await axiosInstance.get(url, {
      responseType: 'blob',
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function post(url, payload,customBaseURL = null) {
  try {
    const { data } = await axiosInstance.post(url, payload, {
      baseURL: customBaseURL || axiosInstance.defaults.baseURL,
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function put(url, payload, spinner = true,) {
  try {
    const { data } = await axiosInstance.put(url, payload, {
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function patch(url, payload, spinner = true,) {
  try {
    const { data } = await axiosInstance.patch(url, payload, {
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function remove(url, queryParams = {}, spinner = true,) {
  try {
    const { data } = await axiosInstance.delete(url, {
      params: { ...queryParams },
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function upload(
  url,
  payload,
  stateObj = {},
  fileName = '',
  spinner = true,
  queryParams = {}
) {
  const config = getUploadConfig(stateObj, fileName)
  try {
    const { data } = await axiosInstance.post(url, payload, {
      params: { ...queryParams },
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

export default { get, post, put, patch, remove, upload, download }
